






































import { defineComponent } from '@vue/composition-api';
import ChartStandardGaugeSections from '@/components/charts/standard/ChartStandardGaugeSections.vue';

export default defineComponent({
  name: 'ChartRehabWidgetJumpCount',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    ChartStandardGaugeSections
  },
  setup(properties) {
    const jumpDiff = properties.data.global.avg_diff_R_L;
    const jumpDiffSide = properties.data.global.side;

    const chartsData = {
      left_foot: {
        value: properties.data.left_foot.sum,
        status: properties.data.left_foot.indicator
      },
      right_foot: {
        value: properties.data.right_foot.sum,
        status: properties.data.right_foot.indicator
      }
    };

    return {
      jumpDiff,
      jumpDiffSide,
      chartsData
    };
  }
});
