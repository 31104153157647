import i18n from '@/plugins/i18n';
import { isNegativ } from '@/helpers/helped-chart-functions.helper';

export enum ESide {
  left = 'left',
  right = 'right'
}
export type CONTAINER = {
  sideLegend: {
    left: POSITION;
    right: POSITION;
  };
  middle: number;
};
export type POSITION = {
  startX: number;
  endX: number;
};

interface Payload {
  avg: number;
  indicator: 'good' | 'warning' | 'bad';
}

export class DrawAnglesFeetSVG {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly type: string;

  private readonly angles: Record<ESide, Payload | undefined> = {
    left: undefined,
    right: undefined
  };

  private readonly pos: any = {
    legend: {
      startY: 0,
      endY: 0
    },
    middleX: 0,
    angles: {
      left: 0,
      right: 0
    }
  };

  private readonly angleName: any = {
    heelStrike: i18n.t('commons.standards.heel-strike') as string,
    flatFoot: i18n.t('commons.standards.toe-strike') as string,
    heelOff: i18n.t('commons.standards.heel-off') as string,
    toesOff: i18n.t('commons.standards.toe-off') as string
  };

  private readonly phaseImg: any = {
    heelStrike: {
      link: require('@/assets/images/heel-strike-shoe.svg'),
      width: 60,
      height: 69
    },
    flatFoot: {
      link: require('@/assets/images/flat-foot-shoe.svg'),
      width: 80,
      height: 69
    },
    heelOff: {
      link: require('@/assets/images/heel-off-shoe.svg'),
      width: 54,
      height: 70.5
    },
    toesOff: {
      link: require('@/assets/images/toe-off-shoe.svg'),
      width: 50,
      height: 82
    }
  };

  constructor(canvas: HTMLCanvasElement, type: string, left: Payload, right: Payload) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;
    this.type = type;

    // Define
    this.angles.left = left;
    this.angles.right = right;

    this.pos.legend.startY = this.canvas.height * 0.7; // get 70%
    this.pos.legend.endY = this.canvas.height;
    this.pos.middleX = this.canvas.width / 2;
    this.pos.angles.left = this.pos.middleX - this.pos.middleX / 2;
    this.pos.angles.right = this.pos.middleX + this.pos.middleX / 2;
  }

  async draw() {
    this.clean();
    //
    this.angleText();
    this.angleLine();
    this.contentStructure();
    this.angleImg();
  }

  // Structure
  contentStructure() {
    // Globals
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#CCCFD7';
    this.ctx.lineCap = 'round';
    const paddingAngulationText = 10;
    this.ctx.font = '500 12px Montserrat';
    this.canvas.style.letterSpacing = '1px';
    this.ctx.globalCompositeOperation = 'destination-over';

    // Horizontal bar
    this.ctx.beginPath();
    this.ctx.moveTo(0, this.pos.legend.startY);
    this.ctx.lineTo(this.canvas.width, this.pos.legend.startY);
    this.ctx.stroke();
    // Vertical bar
    this.ctx.beginPath();
    this.ctx.moveTo(this.pos.middleX, 25);
    this.ctx.lineTo(this.pos.middleX, this.pos.legend.startY);
    this.ctx.stroke();

    // Angulation text
    this.ctx.font = '600 10px Montserrat';
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#c2c7d5';

    // text
    const currentText = this.angleName[this.type].toUpperCase();
    if (this.ctx.measureText(currentText).width > 160) {
      this.cutLargeText(currentText, paddingAngulationText);
    } else {
      this.ctx.fillText(currentText, this.pos.middleX, paddingAngulationText);
    }
  }

  // Angles
  angleText() {
    // Globals
    const paddingText = 25;
    const valuesBackground = {
      height: 25,
      width: 55,
      yPadding: 7
    };
    //
    this.ctx.font = '500 14px Montserrat';
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#3E91DE';
    this.canvas.style.letterSpacing = '0px';

    for (const side of Object.values(ESide)) {
      let bgColor!: string;
      let color!: string;
      switch (this.angles[side]?.indicator) {
        case 'bad':
          color = '#ff7878';
          bgColor = 'rgba(255, 120, 120, 0.12)';
          break;
        case 'warning':
          color = '#f6ab45';
          bgColor = 'rgba(246, 171, 69, 0.1)';
          break;
        case 'good':
          color = '#3ad88e';
          bgColor = 'rgba(37, 192, 144, 0.1)';
          break;
        default:
          color = '#519be1';
          bgColor = '#ebf4fc';
      }

      this.ctx.fillStyle = bgColor;
      this.ctx.beginPath();
      this.ctx.rect(
        this.pos.angles[side] - valuesBackground.width / 2,
        this.pos.legend.startY + 20 - valuesBackground.height / 2,
        valuesBackground.width,
        valuesBackground.height
      );
      this.ctx.fill();

      // Text
      this.ctx.fillStyle = color;
      this.ctx.fillText(
        `${this.angles[side]!.avg} °`,
        this.pos.angles[side],
        this.pos.legend.startY + paddingText
      );
    }
  }

  angleLine() {
    // Globals
    const line = {
      size: 35,
      width: 2.5,
      marginBottom: 2
    };
    const leftAngle = Math.abs(this.angles.left!.avg);
    const rightAngle = Math.abs(this.angles.right!.avg);
    const angles: any = {
      left: {
        x: Math.cos((leftAngle * Math.PI) / 180) * line.size,
        y: Math.sin((leftAngle * Math.PI) / 180) * line.size
      },
      right: {
        x: Math.cos((rightAngle * Math.PI) / 180) * line.size,
        y: Math.sin((rightAngle * Math.PI) / 180) * line.size
      }
    };
    const lineX: any = {
      left: isNegativ(this.angles.left!.avg)
        ? this.pos.angles.left + angles.left.x
        : this.pos.angles.left - angles.left.x,
      right: isNegativ(this.angles.right!.avg)
        ? this.pos.angles.right - angles.right.x
        : this.pos.angles.right + angles.right.x
    };
    //
    this.ctx.lineCap = 'round';
    this.ctx.lineWidth = line.width;

    for (const side of ['left', 'right']) {
      const sideColor = side === 'left' ? '#E8A800' : '#2462FD';
      this.ctx.fillStyle = 'rgba(63,145,222,0.1)';
      this.ctx.strokeStyle = sideColor;
      // Angle shape
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.angles[side], this.pos.legend.startY - line.marginBottom);
      this.ctx.lineTo(lineX[side], this.pos.legend.startY - angles[side].y - line.marginBottom);
      this.ctx.lineTo(lineX[side], this.pos.legend.startY);
      this.ctx.fill();

      // Angle line
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.angles[side], this.pos.legend.startY - line.marginBottom);
      this.ctx.lineTo(lineX[side], this.pos.legend.startY - angles[side].y - line.marginBottom);
      this.ctx.stroke();

      // Baseline point
      this.ctx.beginPath();
      this.ctx.fillStyle = sideColor;
      this.ctx.arc(this.pos.angles[side], this.pos.legend.startY - line.marginBottom, 2, 0, 2 * Math.PI);
      this.ctx.fill();
    }
  }

  angleImg() {
    // GLobals
    const that: any = this;
    const resize = 0.6;
    const angleImages = {
      left: {
        img: new Image(),
        imgSrc: this.phaseImg[this.type].link
      },
      right: {
        img: new Image(),
        imgSrc: this.phaseImg[this.type].link
      },
      height: this.phaseImg[this.type].height,
      width: this.phaseImg[this.type].width
    };

    // resize images
    angleImages.height *= resize;
    angleImages.width *= resize;

    // left
    this.ctx.globalCompositeOperation = 'destination-over';

    angleImages.left.img.addEventListener('load', () => {
      that.ctx!.drawImage(
        angleImages.left.img,
        that.pos.angles.left - angleImages.width / 2,
        that.pos.legend.startY - angleImages.height,
        angleImages.width,
        angleImages.height
      );
    });

    angleImages.left.img.src = angleImages.left.imgSrc;

    // right
    this.ctx.globalCompositeOperation = 'destination-over';

    angleImages.right.img.addEventListener('load', () => {
      that.ctx!.save();
      that.ctx!.scale(-1, 1);
      that.ctx!.drawImage(
        angleImages.right.img,
        (that.pos.angles.right + angleImages.width / 2) * -1,
        that.pos.legend.startY - angleImages.height,
        angleImages.width,
        angleImages.height
      );
      that.ctx!.restore();
    });

    angleImages.right.img.src = angleImages.right.imgSrc;
  }

  cutLargeText(text: string, yPos: number) {
    let index = 0;
    for (const caracter of text) {
      if (caracter === ' ' && index > 15) {
        // console.log(text.slice(0, index), ' --- ', text.slice(index+1, text.length));
        this.ctx.fillText(text.slice(0, index), this.pos.middleX, yPos);
        this.ctx.fillText(text.slice(index + 1, text.length), this.pos.middleX, yPos + 10);
        return;
      }

      index++;
    }
  }

  clean() {
    this.canvas.style.letterSpacing = '0px';
    // Store the current transformation matrix
    this.ctx.save();

    // Use the identity matrix while clearing the canvas
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
