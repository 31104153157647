<template>
  <div v-if="valid" class="chart-card__results">
    <img :src="cdn('image', 'single-hop-jump-profile-graph.svg')" width="491" class="mb-5" />
    <div class="jump-leg-wrapper">
      <div class="jump-leg-side">
        <span class="jump-leg-side-char jump-leg-side-char-left">
          {{ $t('commons.standards.left').charAt(0) }}
        </span>
        <img :src="cdn('image', 'footprint-left.svg')" width="42" class="mb-1" />
        <span class="leg-subtitle">{{ $t('commons.standards.jump-left-leg') }}</span>
      </div>
      <template v-if="isMdAndLarger">
        <div class="jump-leg-bg jump-leg-bg-8"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-24"></div>
        <div v-if="isLgAndLarger" class="jump-leg-bg jump-leg-bg-180"></div>
      </template>
      <div class="jump-leg-bg jump-leg-bg-263 jump-leg-bg--left">
        <span class="mr-2 white--text font-weight-600">{{ $t('commons.standards.flight') }} :</span>
        <VChip small color="white" class="font-weight-bold">{{ data.leftFlight }}</VChip>
      </div>
      <template v-if="isMdAndLarger">
        <div v-if="isLgAndLarger" class="jump-leg-bg jump-leg-bg-180"></div>
        <div class="jump-leg-bg jump-leg-bg-24"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-8"></div>
      </template>
    </div>
    <div class="jump-between-leg-wrapper">
      <div class="d-flex flex-column align-center">
        <div
          class="rounded-chip rounded-chip--left font-weight-bold"
          :class="{ 'opacity-0': data.flightDifferenceSide !== 'left' }"
        >
          {{ $t('commons.standards.left').charAt(0) }}
        </div>
        <VChip x-small color="black" class="white--text my-1 font-weight-bold px-2">
          {{ data.flightDifference }}
        </VChip>
        <div
          class="rounded-chip rounded-chip--right font-weight-bold"
          :class="{ 'opacity-0': data.flightDifferenceSide !== 'right' }"
        >
          {{ $t('commons.standards.right').charAt(0) }}
        </div>
      </div>
      <div class="jump-between-leg-right">
        <span class="indicator">{{ data.leftFootHeight }}</span>
        <span class="sub-indicator">{{ data.footHeightUnit }}</span>
        <span class="font-weight-600 my-3">{{ $t('commons.standards.jump-height-foot') }}</span>
        <span class="indicator">{{ data.rightFootHeight }}</span>
        <span class="sub-indicator">{{ data.footHeightUnit }}</span>
      </div>
    </div>
    <div class="jump-leg-wrapper">
      <div class="jump-leg-side">
        <span class="jump-leg-side-char jump-leg-side-char-right">
          {{ $t('commons.standards.right').charAt(0) }}
        </span>
        <img :src="cdn('image', 'footprint-right.svg')" width="42" class="mb-1" />
        <span class="leg-subtitle">{{ $t('commons.standards.jump-right-leg') }}</span>
      </div>
      <template v-if="isMdAndLarger">
        <div class="jump-leg-bg jump-leg-bg-8"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-24"></div>
        <div v-if="isLgAndLarger" class="jump-leg-bg jump-leg-bg-180"></div>
      </template>
      <div class="jump-leg-bg jump-leg-bg-263 jump-leg-bg--right">
        <span class="mr-2 white--text font-weight-600">{{ $t('commons.standards.flight') }} :</span>
        <VChip small color="white" class="font-weight-bold">{{ data.rightFlight }}</VChip>
      </div>
      <template v-if="isMdAndLarger">
        <div v-if="isLgAndLarger" class="jump-leg-bg jump-leg-bg-180"></div>
        <div class="jump-leg-bg jump-leg-bg-24"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-8"></div>
      </template>
    </div>
  </div>
  <ElementChartUnvailable v-else />
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import components -------------------------------------------------------------------------------
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabSingleHopJumpProfile',
  components: {
    ElementChartUnvailable
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isMdAndLarger, isLgAndLarger } = useVuetifyBreakpoints();
    const { cdn } = useCDN();
    const { getFormattedData, getSimplifiedUnit } = useRehab();

    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.jumping_stride_length &&
        properties.displayMetrics.aggregates.jumping_flight_time
    );

    const data = {
      leftFlight: `${getFormattedData(
        properties.aggregates.jumping_flight_time.left_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      rightFlight: `${getFormattedData(
        properties.aggregates.jumping_flight_time.right_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      leftFootHeight: getFormattedData(properties.aggregates.jumping_foot_height.left_foot.avg),
      rightFootHeight: getFormattedData(properties.aggregates.jumping_foot_height.right_foot.avg),
      footHeightUnit: getSimplifiedUnit(properties.aggregates.jumping_foot_height.unit),
      flightDifference: `${getFormattedData(
        properties.aggregates.jumping_flight_time.global.avg_diff_R_L
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      flightDifferenceSide: properties.aggregates.jumping_flight_time.global.side
    };

    return {
      // Values
      valid,
      isMdAndLarger,
      isLgAndLarger,
      data,
      // Methods
      cdn
    };
  }
});
</script>

<style lang="scss" scoped>
.leg-subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #020c27;
  opacity: 0.5;
}

.font-weight-600 {
  font-weight: 600;
}

.rounded-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  height: 1rem;
  width: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}

.jump-leg-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  gap: 4px;
}

.jump-leg-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;

  &-char {
    font-weight: bold;
    position: absolute;
    color: white;
    font-size: 0.8rem;
    top: 2px;

    &-left {
      left: 55%;
    }

    &-right {
      left: 53%;
    }
  }
}

.jump-between-leg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.25rem 0;
  position: relative;
  width: 100%;
}

.jump-between-leg-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  position: absolute;
}

.jump-leg-bg {
  background-color: #fafafa;
  border-radius: 1000px;
  height: 42px;

  &-8 {
    width: 8px;
  }
  &-16 {
    width: 16px;
  }
  &-24 {
    width: 24px;
  }
  &-180 {
    width: 180px;
  }
  &-263 {
    width: 263px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}

.jump-leg-bg-absolute-bottom {
  position: absolute;
  top: 110%;
  width: 100%;
}

.jump-leg-bg-absolute-top {
  position: absolute;
  bottom: 110%;
  width: 100%;
}

.chip-success {
  background-color: #ebfaea !important;
  color: #33cc30;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  border: 2px solid white;
}

.indicator {
  font-size: 24px;
  font-weight: bold;
  color: #003dd1;
  line-height: 20px;
}

.sub-indicator {
  font-weight: 500;
  font-size: 10px;
  color: #7e8391;
}
</style>
