














































































export type TDatas = {
  taligrade: TSidePhases;
  plantigrade: TSidePhases;
  digitigrade: TSidePhases;
  heelStrike: TAngles;
  flatFoot: TAngles;
  heelOff: TAngles;
  toesOff: TAngles;
};
export type TSidePhases = {
  left: TPhases;
  right: TPhases;
};
export type TPhases = {
  abs: {
    value: number;
    indicators: TPhaseIndicators;
  };
  rel: {
    value: number;
    indicators: TPhaseIndicators;
  };
};
export type TPhaseIndicators = {
  class: string;
  background: string;
  text: string;
  chevron: string | null;
};
export type TAngles = {
  left: {
    abs: TAngle;
    rel: TAngle;
  };
  right: {
    abs: TAngle;
    rel: TAngle;
  };
};
export type TAngle = {
  avg: number;
  stdMin: number;
  stdMax: number;
  range: ERange;
};

export enum ERange {
  VeryLow = 'very_low',
  Low = 'low',
  Neutral = 'neutral',
  High = 'high',
  VeryHigh = 'very_high'
}

export enum EIndicator {
  Bad = 'bad',
  Warning = 'warning',
  good = 'good'
}

// Import vendors ----------------------------------------------------------------------------------
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  watchEffect
} from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
import { DrawAnglesChartSVG } from '@/plugins/charts/prosup-chart/angles-prosup-walking';
// Import helper -----------------------------------------------------------------------------------
import { staticValues } from '@/helpers/static-values.helper';
import { EPhaseValuesTypesUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingPronationSupinationAnglesAdvanced',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    relative: {
      type: Boolean,
      required: true
    },
    simple: {
      type: Boolean
    },
    height: {
      type: Number,
      default: 450
    }
  },
  setup(properties) {
    window.addEventListener('resize', handleResize);

    const rCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rCanvasTooltip: Ref<HTMLCanvasElement | null> = ref(null);
    const rCanvasContent: Ref<HTMLCanvasElement | null> = ref(null);

    const parentWidth = ref(700);
    const width = ref(700);
    const { walkingAngleAverage } = staticValues();

    const data: ComputedRef<TDatas> = computed(() => {
      const data: any = {
        taligrade: {},
        plantigrade: {},
        digitigrade: {},
        heelStrike: {},
        flatFoot: {},
        heelOff: {},
        toesOff: {}
      };

      for (const phase of [
        { taligrade: 'loading' },
        { plantigrade: 'flatfoot' },
        { digitigrade: 'propulsion' }
      ]) {
        data[Object.keys(phase)[0]] = {
          left: {
            abs: {
              value: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].left_foot.avg,
              indicators: getPhaseIndicators(
                properties.aggregates[`walking_${Object.values(phase)[0]}_time`].left_foot.range
              )
            },
            rel: {
              value:
                properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].left_foot.avg,
              indicators: getPhaseIndicators(
                properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].left_foot.range
              )
            }
          },
          right: {
            abs: {
              value: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].right_foot.avg,
              indicators: getPhaseIndicators(
                properties.aggregates[`walking_${Object.values(phase)[0]}_time`].right_foot.range
              )
            },
            rel: {
              value:
                properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].right_foot.avg,
              indicators: getPhaseIndicators(
                properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].right_foot.range
              )
            }
          }
        };
      }

      for (const angle of [
        { heelStrike: 'foot_in' },
        { flatFoot: 'FFI' },
        { heelOff: 'FFO' },
        { toesOff: 'foot_out' }
      ]) {
        data[Object.keys(angle)[0]] = {
          left: {
            abs: {
              avg: properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.avg,
              stdMin:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.avg -
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.std,
              stdMax:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.avg +
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.std,
              range:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].left_foot.range
            },
            rel: {
              avg: properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                .left_foot.avg,
              stdMin:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`].left_foot
                  .avg -
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`].left_foot
                  .std,
              stdMax:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`].left_foot
                  .avg +
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`].left_foot
                  .std,
              range:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`].left_foot
                  .range
            }
          },
          right: {
            abs: {
              avg: properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.avg,
              stdMin:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.avg -
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.std,
              stdMax:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.avg +
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.std,
              range:
                properties.aggregates[`walking_pronation_angle_${Object.values(angle)[0]}`].right_foot.range
            },
            rel: {
              avg: properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                .right_foot.avg,
              stdMin:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                  .right_foot.avg -
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                  .right_foot.std,
              stdMax:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                  .right_foot.avg +
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                  .right_foot.std,
              range:
                properties.aggregates[`walking_relative_pronation_angle_${Object.values(angle)[0]}`]
                  .right_foot.range
            }
          }
        };
      }

      return data;
    });
    const dataMode = computed(() =>
      properties.relative ? EPhaseValuesTypesUnit.Relative : EPhaseValuesTypesUnit.Absolute
    );
    const responsiveData = computed(() => {
      return [
        {
          angle: {
            id: 'toesOff',
            name: i18n.t('commons.standards.toe-off'),
            left: data.value.toesOff.left,
            right: data.value.toesOff.right
          },
          timePhase: {
            id: 'digitigrade',
            icon: require('@/assets/images/propulsion.svg'),
            left: data.value.digitigrade.left,
            right: data.value.digitigrade.right
          }
        },
        {
          angle: {
            id: 'heelOff',
            name: i18n.t('commons.standards.heel-off'),
            left: data.value.heelOff.left,
            right: data.value.heelOff.right
          },
          timePhase: {
            id: 'plantigrade',
            icon: require('@/assets/images/flat-foot.svg'),
            left: data.value.plantigrade.left,
            right: data.value.plantigrade.right
          }
        },
        {
          angle: {
            id: 'flatFoot',
            name: i18n.t('commons.standards.toe-strike'),
            left: data.value.flatFoot.left,
            right: data.value.flatFoot.right
          },
          timePhase: {
            id: 'taligrade',
            icon: require('@/assets/images/heel-strike.svg'),
            left: data.value.taligrade.left,
            right: data.value.taligrade.right
          }
        },
        {
          angle: {
            id: 'heelStrike',
            name: i18n.t('commons.standards.heel-strike'),
            left: data.value.heelStrike.left,
            right: data.value.heelStrike.right
          },
          timePhase: null
        }
      ];
    });

    function getAngleRange(range: ERange) {
      switch (range) {
        case ERange.Low || ERange.VeryLow:
          return 'low-range';
        case ERange.High || ERange.VeryHigh:
          return 'high-range';
        case ERange.Neutral:
          return 'good-range';
        default:
          return 'good-range';
      }
    }

    function getPhaseIndicators(range: ERange): TPhaseIndicators {
      switch (range) {
        case ERange.Neutral:
          return {
            class: 'good-range',
            background: 'rgba(216,220,225,0.35)',
            text: '#5DD0AC',
            chevron: null
          };
        case ERange.Low:
          return {
            class: 'low-range warning-range',
            background: 'rgba(255,189,26,0.15)',
            text: '#ffbd1a',
            chevron: 'down'
          };
        case ERange.High:
          return {
            class: 'high-range warning-range',
            background: 'rgba(255,189,26,0.15)',
            text: '#ffbd1a',
            chevron: 'up'
          };
        case ERange.VeryLow:
          return {
            class: 'low-range bad-range',
            background: 'rgba(255,94,19,0.15)',
            text: '#ff5e13',
            chevron: 'down'
          };
        case ERange.VeryHigh:
          return {
            class: 'high-range bad-range',
            background: 'rgba(255,94,19,0.15)',
            text: '#ff5e13',
            chevron: 'up'
          };
        default: {
          return {
            class: 'good-range',
            background: 'rgba(216,220,225,0.35)',
            text: '#5DD0AC',
            chevron: null
          };
        }
      }
    }

    function handleResize() {
      const parent: any = rCanvasContent.value;
      parentWidth.value = parent?.clientWidth ?? 700;
      width.value = window.innerWidth;
    }

    function draw() {
      if (rCanvas.value) {
        const graphCanvas = new DrawAnglesChartSVG(
          rCanvas.value as HTMLCanvasElement,
          rCanvasTooltip.value as HTMLCanvasElement,
          parentWidth.value,
          data.value,
          walkingAngleAverage,
          dataMode.value
        );
        requestAnimationFrame(() => {
          graphCanvas.draw();
        });
      }
    }

    watchEffect(() => {
      draw();
    });

    onMounted(() => handleResize());
    onUnmounted(() => window.removeEventListener('resize', handleResize));

    return {
      // Values,
      parentWidth,
      width,
      rCanvas,
      dataMode,
      rCanvasContent,
      rCanvasTooltip,
      responsiveData,
      // Methods
      getAngleRange,
      getPhaseIndicators
    };
  }
});
