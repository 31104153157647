






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watchEffect, Ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { DrawAnglesFeetSVG } from '@/plugins/charts/prosup-feet/angles-prosup-walking-feet';
import { nextTick } from 'process';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardPronationSupinationAngles',
  props: {
    values: {
      type: Object,
      required: true
    },
    analysisType: {
      type: String,
      required: true
    },
    angleType: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 400
    }
  },
  setup(properties) {
    const rCanvas: Ref<HTMLCanvasElement | null> = ref(null);

    function draw() {
      if (rCanvas.value) {
        const graphCanvas = new DrawAnglesFeetSVG(
          rCanvas.value,
          Object.keys(properties.angleType)[0],
          properties.values[
            `${properties.analysisType}_pronation_angle_${Object.values(properties.angleType)[0]}`
          ].left_foot,
          properties.values[
            `${properties.analysisType}_pronation_angle_${Object.values(properties.angleType)[0]}`
          ].right_foot
        );
        requestAnimationFrame(() => {
          graphCanvas.draw();
        });
      }
    }

    watchEffect(() => {
      nextTick(() => {
        draw();
      });
    });

    return {
      rCanvas
    };
  }
});
