























































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, toRefs, ref } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingClearanceSteppage',
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const { aggregates } = toRefs(properties);

    function normalizeAngle(angle: number) {
      return angle * -1;
    }

    function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    }

    const defaultChartOptions = {
      cx: 90,
      cy: 90,
      radius: 40,
      startAngle: 0,
      endAngle: 0,
      lineLength: 80,
      invert: false
    };

    function getChart(options: typeof defaultChartOptions) {
      const start = polarToCartesian(options.cx, options.cy, options.radius, options.endAngle);
      const end = polarToCartesian(options.cx, options.cy, options.radius, options.startAngle);
      const largeArcFlag = options.endAngle - options.startAngle <= 180 ? '0' : '1';

      const lineStart = polarToCartesian(
        options.cx,
        options.cy,
        options.lineLength,
        options.invert ? options.startAngle : options.endAngle
      );

      const curveOrigin = {
        x: 208,
        y: 84
      };

      const curveLimitY = curveOrigin.y - (Math.abs(options.endAngle - options.startAngle) / 90) * 1000;

      const curveStart = polarToCartesian(
        curveOrigin.x + 90,
        curveOrigin.y - 10,
        50,
        (options.invert ? options.startAngle : options.endAngle) * 1.1
      );

      const x1 = lineStart.x;
      const y1 = lineStart.y;
      const x2 = options.cx;
      const y2 = options.cy;

      return {
        circularSector: [
          'M',
          start.x,
          start.y,
          'A',
          options.radius,
          options.radius,
          0,
          largeArcFlag,
          0,
          end.x,
          end.y,
          'L',
          options.cx,
          options.cy,
          'Z'
        ].join(' '),
        line: {
          x1,
          y1,
          x2,
          y2
        },
        curve: [
          'M',
          ...[curveOrigin.x, curveOrigin.y], // A point
          'C',
          ...[curveOrigin.x + 90, curveOrigin.y], // A-B bezier point
          ...[curveStart.x - 40, Math.max(Math.min(curveStart.y + 20, curveOrigin.y), curveLimitY)], // B-A bezier point
          ...[curveStart.x, Math.max(Math.min(curveStart.y, curveOrigin.y), curveLimitY)], // B point
          'C',
          ...[curveStart.x + 14, Math.max(Math.min(curveStart.y - 5, curveOrigin.y), curveLimitY)], // B-C bezier point
          ...[curveStart.x + 20, Math.max(Math.min(curveStart.y + 5, curveOrigin.y + 8), curveLimitY + 8)], // C-B bezier point
          ...[curveStart.x + 20, Math.max(Math.min(curveStart.y + 15, curveOrigin.y + 8), curveLimitY + 8)], // C point
          'L',
          ...[curveStart.x + 20, curveOrigin.y + 8], // D point
          'L',
          ...[curveStart.x + 40, curveOrigin.y + 8] // E point
        ].join(' ')
      };
    }

    const data: any = computed(() => {
      return {
        left: {
          color: '#FFBE13',
          textColor: '#B36200',
          height:
            aggregates.value.walking_minimum_toe_clearance.left_foot[isImperial.value ? 'imp_avg' : 'avg'],
          toesOff: {
            value: aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.avg,
            type: aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.range,
            ...getChart({
              ...defaultChartOptions,
              cx: 85,
              cy: 92,
              startAngle: -90,
              endAngle: -90 + aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.avg
            })
          },
          heelStrike: {
            value: normalizeAngle(aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.avg),
            type: aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.range,
            ...getChart({
              ...defaultChartOptions,
              cx: 370,
              cy: 92,
              startAngle: 90 + aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.avg,
              endAngle: 90,
              invert: true
            })
          }
        },
        right: {
          color: '#2462FD',
          textColor: '#0E2D86',
          height:
            aggregates.value.walking_minimum_toe_clearance.right_foot[isImperial.value ? 'imp_avg' : 'avg'],
          toesOff: {
            value: aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.avg,
            type: aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.range,
            ...getChart({
              ...defaultChartOptions,
              cx: 85,
              cy: 92,
              startAngle: -90,
              endAngle: -90 + aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.avg
            })
          },
          heelStrike: {
            value: normalizeAngle(aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.avg),
            type: aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.range,
            ...getChart({
              ...defaultChartOptions,
              cx: 370,
              cy: 92,
              startAngle: 90 + aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.avg,
              endAngle: 90,
              invert: true
            })
          }
        }
      };
    });

    const angles: any = computed(() => {
      return {
        neutral: {
          foot: 'M332.11 62.1c6 7.56 11.35 17.82 13.26 21.83s6.47 12 14 5.28 6.59-12.1 21-13.95c11.24-1.44 13-5.83 16.77-9.66s6.29-7.08 5.92-9.89-2.66-5.29-4.66-4.05-2.91 5-5.81 5.46-7.38 2.63-11.35 2.21-9.3-1.71-14.49-2.21-10.44 0-13.35-2.12-11.18-12.66-14.9-20.74S330.78 20.8 322.25 22s-11.58 9.86-11.12 14.24 3.6 9.12 9.05 13.32 5.9 4.98 11.93 12.54z',
          footCurve: 'M238.09 83.8c89.83-1 117.17-32.25 133.45-32.25q16.72 0 16.73 39.25h30.78',
          footAngleShape: 'M406.2 91.8c0-10.49-2.25-17.85-7-25.08 0 0-13.72 8.41-41.82 25.08z',
          footAngleColor: '#3ad88e',
          footAngleLine: 'M357.09 91.3l105-61.5'
        },
        high: {
          foot: 'M328.59 68.54c7.78 5.74 15.58 14.27 18.46 17.64s9.35 9.91 14.84 1.5 3.24-13.4 16.71-18.93c10.49-4.3 11.06-9 13.7-13.66s4.24-8.47 3.16-11.08-3.95-4.43-5.55-2.71-1.53 5.54-4.2 6.77-6.46 4.45-10.39 5.07-9.43.76-14.57 1.62-10.1 2.67-13.45 1.41-14.06-9.34-19.74-16.17-10.95-11-18.89-7.68-8.67 12.5-7.05 16.61 5.83 7.89 12.18 10.54 7 3.33 14.79 9.07z',
          footCurve: 'M238.34 84.15c89.74-1.39 114.18-43 130.45-43 11.16 0 19.86 15.76 25.86 49.93h30.78',
          footAngleShape: 'M406.34 91.74c0-9.48-2-28.1-11.34-36.15 0 0-11.41 11-36.52 36.15z',
          footAngleColor: 'rgb(255, 179, 26)',
          footAngleLine: 'M358.48 91.74l78-78.5'
        },
        very_high: {
          foot: 'M328.59 68.54c7.78 5.74 15.58 14.27 18.46 17.64s9.35 9.91 14.84 1.5 3.24-13.4 16.71-18.93c10.49-4.3 11.06-9 13.7-13.66s4.24-8.47 3.16-11.08-3.95-4.43-5.55-2.71-1.53 5.54-4.2 6.77-6.46 4.45-10.39 5.07-9.43.76-14.57 1.62-10.1 2.67-13.45 1.41-14.06-9.34-19.74-16.17-10.95-11-18.89-7.68-8.67 12.5-7.05 16.61 5.83 7.89 12.18 10.54 7 3.33 14.79 9.07z',
          footCurve: 'M238.34 84.15c89.74-1.39 114.18-43 130.45-43 11.16 0 19.86 15.76 25.86 49.93h30.78',
          footAngleShape: 'M406.34 91.74c0-9.48-2-28.1-11.34-36.15 0 0-11.41 11-36.52 36.15z',
          footAngleColor: 'rgb(255, 94, 26)',
          footAngleLine: 'M358.48 91.74l78-78.5'
        },
        low: {
          foot: 'M342.49 56.93c3.71 8.93 6 20.26 6.74 24.64s2.91 13.3 11.95 8.92 9.68-9.82 24.07-7.61c11.2 1.71 14.11-2 18.78-4.67s8-5.07 8.42-7.87-1.11-5.82-3.37-5.17-4.16 4-7.09 3.64-7.82.49-11.51-1-8.48-4.21-13.32-6.13-10-2.9-12.26-5.71-7.24-15.25-8.59-24-3.72-15.07-12.23-16.31-13.85 6.28-14.61 10.62.94 9.76 5 15.3 4.31 6.42 8.02 15.35z',
          footCurve: 'M238.09 84c90-.64 124.17-19 130.45-19 11.15 0 16.4 7.68 15.73 25.85h30.78',
          footAngleShape: 'M406 91.8a51.71 51.71 0 00-1-10.45s-13.88 3.18-45.91 10.46z',
          footAngleColor: 'rgb(255, 179, 26)',
          footAngleLine: 'M358.46 91.89l108.5-24.17'
        },
        very_low: {
          foot: 'M342.49 56.93c3.71 8.93 6 20.26 6.74 24.64s2.91 13.3 11.95 8.92 9.68-9.82 24.07-7.61c11.2 1.71 14.11-2 18.78-4.67s8-5.07 8.42-7.87-1.11-5.82-3.37-5.17-4.16 4-7.09 3.64-7.82.49-11.51-1-8.48-4.21-13.32-6.13-10-2.9-12.26-5.71-7.24-15.25-8.59-24-3.72-15.07-12.23-16.31-13.85 6.28-14.61 10.62.94 9.76 5 15.3 4.31 6.42 8.02 15.35z',
          footCurve: 'M238.09 84c90-.64 124.17-19 130.45-19 11.15 0 16.4 7.68 15.73 25.85h30.78',
          footAngleShape: 'M406 91.8a51.71 51.71 0 00-1-10.45s-13.88 3.18-45.91 10.46z',
          footAngleColor: 'rgb(255, 94, 26)',
          footAngleLine: 'M358.46 91.89l108.5-24.17'
        }
      };
    });

    const unit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      // Values
      data,
      angles,
      unit
    };
  }
});
