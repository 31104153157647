import i18n from '@/plugins/i18n';
import {
  getYTooltipPosition,
  getStdValueRange,
  getAngleRange
} from '@/helpers/helped-chart-functions.helper';

export class DrawTooltip {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly mode: string;
  private readonly height: number;

  private readonly values: any;

  private readonly container: any = {
    sideLegend: {
      left: {
        startX: 0,
        endX: 0
      },
      right: {
        startX: 0,
        endX: 0
      }
    },
    chart: {
      height: 0,
      width: 0,
      paddingTop: 80,
      paddingBottom: 40,
      scale: {
        min: -30,
        max: 10,
        zones: [0, 10, 20, 30, 40, 50]
      }
    },
    middle: 0
  };

  private readonly corridor: any = {
    toesOff: {
      min: 0,
      max: 0
    },
    heelOff: {
      min: 0,
      max: 0
    },
    flatFoot: {
      min: 0,
      max: 0
    },
    heelStrike: {
      min: -0,
      max: 0
    }
  };

  private readonly tooltipsPos: any = {
    toesOff: {
      left: {
        x: 0,
        y: 0
      },
      right: {
        x: 0,
        y: 0
      }
    },
    heelOff: {
      left: {
        x: 0,
        y: 0
      },
      right: {
        x: 0,
        y: 0
      }
    },
    flatFoot: {
      left: {
        x: 0,
        y: 0
      },
      right: {
        x: 0,
        y: 0
      }
    },
    heelStrike: {
      left: {
        x: 0,
        y: 0
      },
      right: {
        x: 0,
        y: 0
      }
    }
  };

  private readonly translations: any = {
    toesOff: i18n.t('commons.standards.toe-off') as string,
    heelOff: i18n.t('commons.standards.heel-off') as string,
    flatFoot: i18n.t('commons.standards.toe-strike') as string,
    heelStrike: i18n.t('commons.standards.heel-strike') as string,
    left: i18n.t('commons.standards.left') as string,
    right: i18n.t('commons.standards.right') as string,
    corridor: i18n.t('commons.standards.corridor') as string,
    standardDeviation: i18n.t('commons.standards.standard-deviation') as string
  };

  constructor(
    canvas: HTMLCanvasElement,
    container: any,
    values: any,
    corridor: any,
    tooltipsPos: any,
    mode: string
  ) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;
    this.mode = mode;
    this.height = this.canvas.height;

    this.container = container;
    this.values = values;
    this.corridor = corridor;
    this.tooltipsPos = tooltipsPos;
  }

  async drawTooltip() {
    this.clean();

    this.getTooltipZone();
    // this.getTooltipWindow();
  }

  getTooltipZone() {
    // Globals
    const tooltipZones: any = [];
    let index = 0;
    let zone;

    const height = 20;
    const width = this.container.middle - this.container.sideLegend.left.endX;
    const startX: any = {
      left: this.container.sideLegend.left.endX,
      right: this.container.middle
    };
    this.ctx.fillStyle = 'rgba(0,0,0,0)';

    for (const side of ['left', 'right']) {
      for (const type of ['toesOff', 'heelOff', 'flatFoot', 'heelStrike']) {
        /* this.ctx.beginPath();
        this.ctx.rect(startX[side], this.tooltipsPos[type][side].y - height / 2, width, height);
        this.ctx.fill(); */

        tooltipZones.push({
          side,
          type,
          x: startX[side],
          y: this.tooltipsPos[type][side].y - height / 2,
          w: width,
          h: height
        });
      }
    }

    while ((zone = tooltipZones[index++])) {
      this.ctx.rect(zone.x, zone.y, zone.w, zone.h);
      this.ctx.fillStyle = 'rgba(0,0,0,0)';
      this.ctx.fill();
    }

    const that: any = this;
    this.canvas.addEventListener('mousemove', (event: any) => {
      const rect = that.canvas.getBoundingClientRect();
      const x = event.clientX - rect.x;
      const y = event.clientY - rect.y;
      let index_ = 0;
      let zone = null;

      that.ctx.clearRect(0, 0, that.canvas.width, that.canvas.height);

      while ((zone = tooltipZones[index_++])) {
        that.ctx.beginPath();
        that.ctx.rect(zone.x, zone.y, zone.w, zone.h);
        that.ctx.fillStyle = 'rgba(0,0,0,0)';
        that.ctx.fill();
        that.ctx.fill();

        if (that.ctx.isPointInPath(x, y)) {
          that.getPointHover(zone.side, zone.type);
          that.getTooltipWindow(zone.side, zone.type);
        }
      }
    });
  }

  getPointHover(side: string, type: string) {
    this.ctx.fillStyle = 'rgba(103,108,124,0.25)';
    this.ctx.beginPath();
    this.ctx.arc(this.tooltipsPos[type][side].x, this.tooltipsPos[type][side].y, 15, 0, 2 * Math.PI);
    this.ctx.fill();
  }

  getTooltipWindow(side: string, type: string) {
    // Globals
    const height = 75;
    const width = 280;
    const padding = 15;
    const startX: any = {
      left: this.tooltipsPos[type][side].x + padding,
      right: this.tooltipsPos[type][side].x - padding - width
    };
    const y = getYTooltipPosition(this.tooltipsPos[type][side].y, height, this.container, this.height);

    this.ctx.shadowBlur = 10;
    this.ctx.shadowOffsetX = 2;
    this.ctx.shadowOffsetY = 2;

    this.ctx.fillStyle = 'rgba(255,255,255,0.95)';
    this.ctx.shadowColor = 'rgba(52,52,52,0.2)';

    this.ctx.beginPath();
    this.ctx.rect(startX[side], y, width, height);
    this.ctx.fill();

    this.getTooltipWindowText(type, side, startX[side], y, width, height);

    // Reset shadow
    this.ctx.shadowColor = 'rgba(52,52,52,0)';
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;

    this.getArrowIndicator(side, this.tooltipsPos[type][side].y, startX, width);
  }

  getArrowIndicator(side: string, yPointPos: number, windowX: any, windowWidth: number) {
    const height = 25;
    const width = 10;
    const isLeftSide = side === 'left';
    const posX: any = {
      left: windowX.left,
      right: windowX.right + windowWidth
    };
    const arrowPoint = isLeftSide ? width * -1 : width;

    // Triangle - arrow indicator
    this.ctx.beginPath();
    this.ctx.moveTo(posX[side], yPointPos - height / 2);
    this.ctx.lineTo(posX[side] + arrowPoint, yPointPos);
    this.ctx.lineTo(posX[side], yPointPos + height / 2);
    this.ctx.closePath();

    this.ctx.fillStyle = 'rgba(255,255,255)';

    this.ctx.fill();
  }

  getTooltipWindowText(type: string, side: string, x: number, y: number, width: number, height: number) {
    // Globals
    const paddingX = 20;
    const paddingY = 28;
    const largePaddingY = 35;
    const pos = {
      min: {
        x: paddingX * 9.5,
        y: paddingY * 2
      },
      max: {
        x: paddingX * 12,
        y: paddingY * 2
      }
    };
    this.ctx.fillStyle = '#717788';

    let angleText = `${this.translations[type]} (${this.translations[side]})`;
    const valueText = `${this.values[type][side][this.mode].avg}°`;

    const isSmallangleText = this.ctx.measureText(angleText).width < 150;
    angleText = isSmallangleText
      ? angleText
      : `${this.translations[type]} (${this.translations[side].slice(0, 1)})`;

    this.ctx.textAlign = 'left';
    this.ctx.font = '600 24px Montserrat';
    this.ctx.fillStyle = getAngleRange(
      this.values[type][side][this.mode].avg,
      type,
      this.mode,
      this.corridor
    );
    this.ctx.fillText(valueText, x + paddingX, y + largePaddingY);
    this.ctx.font = '500 13px Montserrat';
    this.ctx.fillStyle = '#9BA0B0';
    this.ctx.fillText(angleText, x + paddingX, y + paddingY * 2);
    this.ctx.textAlign = 'center';
    this.ctx.fillText('Min', x + pos.min.x, y + pos.min.y);
    this.ctx.fillText('Max', x + pos.max.x, y + pos.max.y);
    this.ctx.font = '600 13px Montserrat';
    this.ctx.fillStyle = getStdValueRange(
      this.values[type][side][this.mode].stdMax,
      type,
      this.mode,
      this.corridor
    ).color;
    this.ctx.fillText(
      getStdValueRange(this.values[type][side][this.mode].stdMax, type, this.mode, this.corridor).text,
      x + pos.min.x,
      y + largePaddingY
    );
    this.ctx.fillStyle = getStdValueRange(
      this.values[type][side][this.mode].stdMin,
      type,
      this.mode,
      this.corridor
    ).color;
    this.ctx.fillText(
      getStdValueRange(this.values[type][side][this.mode].stdMin, type, this.mode, this.corridor).text,
      x + pos.max.x,
      y + largePaddingY
    );
  }

  clean() {
    // Store the current transformation matrix
    this.ctx.save();

    // Use the identity matrix while clearing the canvas
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
