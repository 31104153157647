













































































































































































































































































































































































































































































































































































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningComparisonTable',
  props: {
    firstValue: {
      type: Object,
      default: null
    },
    secondValue: {
      type: Object,
      default: null
    }
  },
  setup(properties) {
    const badCompareData: Ref<boolean> = ref(false);
    const sides = ['left_foot', 'right_foot'];
    const first = computed(() => properties.firstValue.data);
    const second = computed(() => properties.secondValue.data);

    function getAsymmetryInfos(asymmetry: { avg: number; range: string; side: 'left' | 'right' }) {
      const leftProgress = asymmetry.avg * 2.5;
      const rightProgress = asymmetry.avg * 2.5;

      return {
        range: asymmetry.range,
        leftProgress,
        rightProgress,
        value: asymmetry.avg,
        isLeftSideSymmetry: asymmetry.side === 'left'
      };
    }

    function getForeFoot(type: string, side: string) {
      const properties_: any = properties;
      const value: any = properties_[type];

      return getFootImg(value.data.values.strikePattern[side].angleTypes);
    }

    function getFootImg(type: string) {
      switch (type) {
        case 'foreFoot':
          return 'attack-toe';
        case 'rearFoot':
          return 'attack-heel';
        default:
          return 'attack-flat';
      }
    }

    function getDifference(first: number, second: number) {
      if (second > first) return 'long-arrow-up';
      if (second < first) return 'long-arrow-down';
      return 'long-arrow-right';
    }

    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));
    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));

    return {
      // Values
      first,
      second,
      badCompareData,
      sides,
      // Units
      speedUnit,
      strideLengthUnit,
      // Methods
      getAsymmetryInfos,
      getForeFoot,
      getDifference
    };
  }
});
