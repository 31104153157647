













































































































































































































































// Import vendors --------------------------------------------------------------
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from '@vue/composition-api';
// Import components -----------------------------------------------------------
import PatientResultsComparisonWalking from '@/components/patient/results/comparison/PatientResultsComparisonWalking.vue';
import PatientResultsComparisonWalkingDegraded from '@/components/patient/results/comparison/PatientResultsComparisonWalkingDegraded.vue';
import PatientResultsComparisonRunning from '@/components/patient/results/comparison/PatientResultsComparisonRunning.vue';
import PatientResultsComparisonJumping from '@/components/patient/results/comparison/PatientResultsComparisonJumping.vue';
// Import plugins --------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import helper-----------------------------------------------------------------
import { isDegradedAnalysis } from '@/helpers/helped-functions.helper';
// Import utils -----------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useRehab } from '@/utils/rehab.utils';
import { usePatient } from '@/utils/patient.utils';
// Import config ---------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePatientResultsComparison',
  components: {
    PatientResultsComparisonWalking,
    PatientResultsComparisonWalkingDegraded,
    PatientResultsComparisonRunning,
    PatientResultsComparisonJumping
  },
  setup(_, { root }) {
    const { params, query } = useRoute();
    const { trackSuccess } = useAnalytics();
    const { getMode } = useRehab();
    const patient = usePatient();

    const busModule = usePodocoreModule('bus');
    const requestModule: any = usePodocoreModule('request');

    const analysisList = reactive<any[]>([]);
    const analysisListRequest = ref<any>(null);
    const analysisListHasNext = ref(false);

    // -------------------------------------------

    let analysisFirstRequest = ref<any>(null);
    let analysisSecondRequest = ref<any>(null);
    const firstAnalysis: any = ref(null);
    const secondAnalysis: any = ref(null);

    const firstAnalysisByType = computed(() => {
      return secondAnalysis.value
        ? analysisList.filter((x: any) => x.cuid !== secondAnalysis.value.cuid)
        : analysisList;
    });
    const secondAnalysisByType = computed(() => {
      return firstAnalysis.value
        ? analysisList.filter((x: any) => {
            if (x.cuid === firstAnalysis.value.cuid) return false;
            if (x._scenario.key.startsWith('walking'))
              return firstAnalysis.value._scenario.key.startsWith('walking');
            if (x._scenario.key.startsWith('running'))
              return firstAnalysis.value._scenario.key.startsWith('running');
            if (x._scenario.key.startsWith('jumping'))
              return getMode(x._scenario.key) === getMode(firstAnalysis.value._scenario.key);
            return false;
          })
        : [];
    });
    const values: any = reactive({
      first: null,
      second: null
    });

    const haveDegradedWalkingAnalysis = computed(() => {
      return (
        isDegradedAnalysis(analysisFirstRequest.value?.data?.computedData) ||
        isDegradedAnalysis(analysisSecondRequest.value?.data?.computedData)
      );
    });

    function configureRequest(page?: number) {
      if (analysisListRequest.value?.cancel) analysisListRequest.value.cancel();
      analysisListRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/analysis`, {
        axios: {
          params: {
            patientCuid: params.value.cuid,
            sort: [['createdAt', '-1']],
            page,
            latestStatus: 'completed'
          }
        }
      });
      analysisListRequest.value.request();
    }

    function loadMore() {
      configureRequest(analysisListRequest.value.data.nextPage);
    }

    function openExport() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-export-pdf',
          meta: {
            scenario: firstAnalysis.value._scenario.key,
            analysis: {
              first: values.first.data,
              second: values.second.data
            },
            patient: patient.data,
            mode: 'comparison'
          }
        })
      );
      trackPDFExport();
    }
    function trackPDFExport() {
      trackSuccess('PatientAnalysis/Comparison/Export to PDF', {
        activityType: firstAnalysis.value.activityType
      });
    }

    // Analysis
    watch(
      () => analysisListRequest.value?.data,
      (data) => {
        if (data) {
          analysisList.push(...data.docs);
          analysisListHasNext.value = data.hasNextPage;
          addValuesByQuery();
        }
      }
    );

    // Queries
    watch(
      query,
      () => {
        addValuesByQuery();
      },
      { deep: true }
    );

    // First Analysis
    watch(
      firstAnalysis,
      () => {
        if (firstAnalysis.value) {
          // If analysis type change

          if (
            secondAnalysis.value &&
            firstAnalysis.value._scenario.key != secondAnalysis.value._scenario.key
          ) {
            secondAnalysis.value = null;
          }

          if (query.value.firstAnalysis != firstAnalysis.value.cuid) {
            root.$router.push({
              name: 'patient-comparison',
              query: { firstAnalysis: firstAnalysis.value.cuid }
            });
          }

          analysisFirstRequest.value = requestModule.useAuthenticatedRequest(
            `${apiConfig.default}/analysis/${firstAnalysis.value.cuid}`
          );
          analysisFirstRequest.value.request();
        }
      },
      { deep: true }
    );
    // Second Analysis
    watch(
      secondAnalysis,
      (value) => {
        if (secondAnalysis.value) {
          if (query.value.secondAnalysis != secondAnalysis.value.cuid) {
            root.$router.push({
              name: 'patient-comparison',
              query: {
                firstAnalysis: firstAnalysis.value.cuid,
                secondAnalysis: secondAnalysis.value.cuid
              }
            });
          }
          analysisSecondRequest.value = requestModule.useAuthenticatedRequest(
            `${apiConfig.default}/analysis/${secondAnalysis.value.cuid}`
          );
          analysisSecondRequest.value.request();
        }
      },
      { deep: true }
    );

    const isPending = computed(() => {
      return (
        analysisFirstRequest.value?.isPending ||
        analysisSecondRequest.value?.isPending ||
        analysisListRequest.value?.isPending
      );
    });

    // First Analysis request
    watch(
      () => analysisFirstRequest.value?.data,
      (data) => {
        if (data) {
          values.first = {
            ...firstAnalysis.value,
            data: analysisFirstRequest.value.data
          };
          if (!analysisList.find((x) => x.cuid == query.value.firstAnalysis)) {
            analysisList.push(data);
          }
          analysisFirstRequest.value = null;
        }
      }
    );

    // Second Analysis request
    watch(
      () => analysisSecondRequest.value?.data,
      (data) => {
        if (data) {
          values.second = {
            ...secondAnalysis.value,
            data: analysisSecondRequest.value.data
          };
          trackSuccess('PatientAnalysis/Compare', {
            analysisType: secondAnalysis.value._scenario.key
          });
          analysisSecondRequest.value = null;
        }
      }
    );

    function addValuesByQuery() {
      if (query.value.firstAnalysis) {
        firstAnalysis.value = analysisList.find((x: any) => x.cuid === query.value.firstAnalysis);
      }
      if (query.value?.secondAnalysis) {
        secondAnalysis.value = analysisList.find((x: any) => x.cuid === query.value.secondAnalysis);
      }
    }

    onMounted(() => {
      analysisFirstRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${query.value.firstAnalysis}`
      );
      analysisFirstRequest.value.request();
      // Load analysis
      configureRequest();
    });

    onBeforeUnmount(() => {
      analysisListRequest.value.cancel();
    });

    const getAnalysisIconBgColor = (scenarioKey: string, degraded: boolean) => {
      if (degraded) return 'grey';
      if (scenarioKey.startsWith('walking')) return 'walking';
      if (scenarioKey.startsWith('running')) return 'running';
      return 'rehab';
    };

    return {
      analysisList,
      analysisListHasNext,
      // Values
      values,
      firstAnalysis,
      secondAnalysis,
      haveDegradedWalkingAnalysis,
      firstAnalysisByType,
      secondAnalysisByType,
      // Methods
      loadMore,
      getAnalysisIconBgColor,
      getMode,
      openExport,
      // Flags
      // Helpers
      isDegradedAnalysis,
      isPending
    };
  }
});
