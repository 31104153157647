<template>
  <div class="graph-wrapper">
    <div class="d-flex">
      <div class="graph-left-side">
        <img :src="cdn('image', 'rehab-symmetry-left-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">3</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-left-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">2</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-left-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">1</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-left-foot.svg')" width="31" />
      </div>
      <div class="text-center py-3 d-flex flex-column align-center">
        <img :src="cdn('image', 'rehab-symmetry-left-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump3.leftSymmetry }}</VChip>
        <div class="gray-separator-wrapper">
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump3.advancedLeftDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump3.advancedLeftTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump3.leftDistance }}</VChip>
        </div>
        <img :src="cdn('image', 'rehab-symmetry-left-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump2.leftSymmetry }}</VChip>
        <div class="gray-separator-wrapper">
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump2.advancedLeftDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump2.advancedLeftTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump2.leftDistance }}</VChip>
        </div>
        <img :src="cdn('image', 'rehab-symmetry-left-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump1.leftSymmetry }}</VChip>
        <div class="gray-separator-wrapper">
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump1.advancedLeftDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump1.advancedLeftTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump1.leftDistance }}</VChip>
        </div>
      </div>
    </div>
    <div class="graph-center">
      <div class="graph-center__advanced-shoes-time">
        <template v-if="isAdvancedMode && data.jump3.advancedCompareTimeDiff">
          <VChip x-small color="white">{{ data.jump3.advancedLeftCompareTime }}</VChip>
          <div class="d-flex">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump3.advancedCompareTimeDiffSide !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump3.advancedCompareTimeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump3.advancedCompareTimeDiffSide !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <VChip x-small color="white">{{ data.jump3.advancedRightCompareTime }}</VChip>
        </template>
      </div>
      <div class="graph-center__separator-wrapper">
        <img :src="cdn('image', 'rehab-triple-hop-graph-black-separator.svg')" />
        <div class="graph-center__chip-wrapper d-flex flex-column align-center mb-1">
          <div class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump3.heightSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="black" class="white--text mx-1 font-weight-bold px-2">
              {{ data.jump3.heightDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump3.heightSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <div v-if="isAdvancedMode" class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump3.timeSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump3.timeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump3.timeSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
        </div>
      </div>
      <div class="graph-center__advanced-shoes-time">
        <template v-if="isAdvancedMode && data.jump2.advancedCompareTimeDiff">
          <VChip x-small color="white">{{ data.jump2.advancedLeftCompareTime }}</VChip>
          <div class="d-flex">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump2.advancedCompareTimeDiffSide !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump2.advancedCompareTimeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump2.advancedCompareTimeDiffSide !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <VChip x-small color="white">{{ data.jump2.advancedRightCompareTime }}</VChip>
        </template>
      </div>
      <div class="graph-center__separator-wrapper">
        <img :src="cdn('image', 'rehab-triple-hop-graph-black-separator.svg')" />
        <div class="graph-center__chip-wrapper d-flex flex-column align-center mb-1">
          <div class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump2.heightSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="black" class="white--text mx-1 font-weight-bold px-2">
              {{ data.jump2.heightDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump2.heightSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <div v-if="isAdvancedMode" class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump2.timeSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump2.timeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump2.timeSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
        </div>
      </div>
      <div class="graph-center__advanced-shoes-time">
        <template v-if="isAdvancedMode && data.jump1.advancedCompareTimeDiff">
          <VChip x-small color="white">{{ data.jump1.advancedLeftCompareTime }}</VChip>
          <div class="d-flex">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump1.advancedCompareTimeDiffSide !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump1.advancedCompareTimeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump1.advancedCompareTimeDiffSide !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <VChip x-small color="white">{{ data.jump1.advancedRightCompareTime }}</VChip>
        </template>
      </div>
      <div class="graph-center__separator-wrapper mb-5">
        <img :src="cdn('image', 'rehab-triple-hop-graph-black-separator.svg')" />
        <div class="graph-center__chip-wrapper d-flex flex-column align-center mb-1">
          <div class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump1.heightSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="black" class="white--text mx-1 font-weight-bold px-2">
              {{ data.jump1.heightDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump1.heightSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
          <div v-if="isAdvancedMode" class="d-flex w-100 justify-space-between">
            <div
              class="rounded-chip rounded-chip--left"
              :class="{ 'opacity-0': data.jump1.timeSideDiff !== 'left' }"
            >
              {{ $t('commons.standards.left').charAt(0) }}
            </div>
            <VChip x-small color="#7E8391" class="white--text mx-1 font-weight-bold">
              {{ data.jump1.timeDiff }}
            </VChip>
            <div
              class="rounded-chip rounded-chip--right"
              :class="{ 'opacity-0': data.jump1.timeSideDiff !== 'right' }"
            >
              {{ $t('commons.standards.right').charAt(0) }}
            </div>
          </div>
        </div>
      </div>
      <h1 class="start">{{ $t('commons.standards.start') }}</h1>
    </div>
    <div class="d-flex">
      <div class="text-center py-3 d-flex flex-column align-center">
        <img :src="cdn('image', 'rehab-symmetry-right-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump3.rightSymmetry }}</VChip>
        <div class="gray-separator-wrapper justify-end">
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump3.advancedRightDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump3.advancedRightTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump3.rightDistance }}</VChip>
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
        </div>
        <img :src="cdn('image', 'rehab-symmetry-right-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump2.rightSymmetry }}</VChip>
        <div class="gray-separator-wrapper justify-end">
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump2.advancedRightDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump2.advancedRightTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump2.rightDistance }}</VChip>
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
        </div>
        <img :src="cdn('image', 'rehab-symmetry-right-foot-graph.svg')" width="98" class="mb-2" />
        <VChip small class="chip-success mb-1">{{ data.jump1.rightSymmetry }}</VChip>
        <div class="gray-separator-wrapper justify-end">
          <div v-if="isAdvancedMode" class="d-flex flex-column">
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump1.advancedRightDistance }}
            </VChip>
            <VChip x-small color="gray" class="mb-1">
              {{ data.jump1.advancedRightTime }}
            </VChip>
          </div>
          <VChip v-else small color="white" class="mb-1">{{ data.jump1.rightDistance }}</VChip>
          <img :src="cdn('image', 'rehab-triple-hop-graph-gray-separator.svg')" />
        </div>
      </div>
      <div class="graph-right-side">
        <img :src="cdn('image', 'rehab-symmetry-right-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">3</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-right-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">2</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-right-foot.svg')" width="31" />
        <div class="between-feet-line"></div>
        <div class="between-feet-bubble">1</div>
        <div class="between-feet-line"></div>
        <img :src="cdn('image', 'rehab-symmetry-right-foot.svg')" width="31" />
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabTripleHopJumpProfile',
  props: {
    data: Object,
    isAdvancedMode: Boolean
  },
  setup() {
    const { cdn } = useCDN();

    return {
      // Methods
      cdn
    };
  }
});
</script>

<style lang="scss" scoped>
.graph-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.graph-left-side {
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.graph-right-side {
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.between-feet-line {
  height: 3rem;
  width: 2px;
  background-color: #020c27;
}

.between-feet-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  border: 2px solid #020c27;
  font-weight: 600;
}

.gray-separator-wrapper {
  display: flex;
  align-items: center;
  color: #7e8391;

  span {
    font-weight: 500;
  }
}

.graph-center {
  position: relative;

  &__advanced-shoes-time {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81px;
  }

  &__separator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__chip-wrapper {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    > div {
      border: 2px solid white;
    }
  }
}

.rounded-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  height: 1rem;
  width: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;
  font-weight: 600;

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}

.chip-success {
  background-color: #ebfaea !important;
  color: #33cc30;
  font-weight: 600;
}

.start {
  position: absolute;
  left: 50%;
  font-size: 2rem;
  font-weight: 600;
  transform: translateX(-50%);
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #fafafa;
}
</style>
