





















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ChartRehabWidgetTotalDistance',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
});
